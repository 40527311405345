html {
  -webkit-text-size-adjust: 100%;
  line-height: 1.5;
}

html, * {
  box-sizing: border-box;
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Ubuntu, Helvetica Neue, Helvetica, sans-serif;
}

body {
  color: #212529;
  background: linear-gradient(135deg, #764abc, #264de4);
  min-height: 100vh;
  margin: 0;
}

@media (width <= 768px) {
  body {
    font-size: 12px;
  }
}

h1 {
  margin: .67em 0;
  font-size: 2em;
}

li {
  list-style: none;
}

ul {
  padding: 0;
}

.wrapper {
  padding: 1.4em;
}

.container {
  background: #fff;
  border-radius: 12px;
  max-width: 540px;
  margin: auto;
  padding: 1.4em 1.5em 40px;
}

.container h1 {
  align-items: center;
  gap: 16px;
  display: flex;
}

.link-form {
  background: #dee2e6;
  border-radius: 30px;
  justify-content: center;
  align-items: center;
  padding-left: 20px;
  display: flex;
}

.link-form input {
  background: none;
  border: none;
  outline: none;
  flex: 1;
  padding: 10px;
}

.invalid input {
  color: red;
  border-bottom: 1px solid red;
}

.link-form button {
  color: #0d6efd;
  cursor: pointer;
  background: #6ea8fe66;
  border: none;
  border-radius: 30px;
  width: 132px;
  padding: 16px;
  font-weight: 600;
  transition-property: background-color;
  transition-duration: .15s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

.link-form button:hover, .link-form button:focus {
  background: #6ea8fe80;
  font-weight: 700;
}

.link-form button:disabled {
  color: #343a40;
  cursor: not-allowed;
  background: #ced4da;
}

.link-form-error-msg {
  color: red;
  margin-top: 4px;
  margin-bottom: 25px;
  margin-left: 32px;
  font-size: .8em;
}

.bookmark-list-container {
  flex-direction: column;
  justify-content: center;
  display: flex;
}

.icon-button {
  appearance: none;
  cursor: pointer;
  color: var(--secondary-icon-button);
  background-color: #0000;
  border: none;
  outline: none;
  height: fit-content;
  font-size: 16px;
  display: block;
}

.icon-button:hover {
  color: var(--secondary-icon-button-active);
}

.bookmark-list {
  flex-direction: column;
  justify-content: left;
  gap: 12px;
  display: flex;
}

.bookmark-list-item {
  background: #e9ecef;
  border-radius: 6px;
}

.bookmark-list-wrapper {
  background: #b4b4e633;
  border-radius: 6px;
  flex-grow: 1;
  gap: 16px;
  padding: 12px 8px;
  display: flex;
}

.bookmark-item-img {
  background: #ced4da;
  border-radius: 6px;
  width: 64px;
  height: 64px;
}

@media (width <= 768px) {
  .bookmark-item-img {
    width: 48px;
    height: 48px;
  }
}

img {
  border-radius: 6px;
  width: 100%;
  height: 100%;
}

.bookmark-list-item-content {
  flex-direction: column;
  width: 58%;
  display: flex;
}

.bookmark-list-item-content h3 {
  white-space: nowrap;
  text-overflow: ellipsis;
  margin: 0;
  font-size: 1.2em;
  overflow: hidden;
}

.bookmark-list-item-content a {
  color: #6c757d;
  white-space: nowrap;
  text-overflow: ellipsis;
  width: 100%;
  margin-top: auto;
  font-size: .8em;
  text-decoration: none;
  overflow: hidden;
}

.bookmark-list-item-action {
  flex-wrap: wrap;
  justify-content: end;
  display: flex;
}

.bookmark-item-date {
  text-align: right;
  color: #6c757d;
  width: max-content;
  margin-top: auto;
  padding-right: 12px;
  font-size: .6em;
}

@media (width <= 768px) {
  .bookmark-item-date {
    display: none;
  }
}

.bookmark-list-item-warper {
  flex-direction: column;
  margin-left: auto;
  display: flex;
}

.bookmark-list-edit-input {
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  padding: 0 24px 8px;
  font-size: .8em;
  display: flex;
}

.bookmark-list-edit-input-wrapper {
  background: #fff;
  border-radius: 0 0 8px 8px;
  padding: 0 8px 8px;
}

.bookmark-list-edit-input p {
  margin: 0 0 4px;
  font-weight: 700;
}

.bookmark-list-edit-input input {
  background: none;
  border: none;
  border-bottom: 1px solid #000;
  outline: none;
}

.invalid {
  color: red;
  border-color: red !important;
}

.save-button {
  color: green;
  cursor: pointer;
  background: #fff;
  border: none;
  border-radius: 8px;
  padding: 8px;
  font-weight: 600;
}

.save-button:hover, .save-button:focus {
  scale: 1.1;
}

.hidden {
  display: none;
}

.pagination {
  justify-content: center;
  display: flex;
}

.page-link {
  cursor: pointer;
  margin: 2px;
  padding: 5px;
}

.page-link.active {
  background-color: #eee;
  border-radius: 4px;
  font-weight: bold;
}

.submission-info-img {
  cursor: pointer;
  background: #ced4da;
  border-radius: 6px;
  width: 100%;
  min-height: 300px;
}

.return-button {
  cursor: pointer;
  color: #0d6efd;
  background: #6ea8fe66;
  border: none;
  border-radius: 8px;
  width: 100%;
  padding: 16px;
  font-size: 20px;
  font-weight: 700;
}
/*# sourceMappingURL=index.5c83faee.css.map */
