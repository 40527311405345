html {
  line-height: 1.5;
  -webkit-text-size-adjust: 100%;
}

html,
* {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Ubuntu,
    "Helvetica Neue", Helvetica, sans-serif;
  box-sizing: border-box;
}

body {
  margin: 0;
  min-height: 100vh;
  background: linear-gradient(135deg, #764abc, #264de4);
  color: #212529;
}

@media (max-width: 768px) {
  body {
    font-size: 12px;
  }
}

h1 {
  font-size: 2em;
  margin: 0.67em 0;
}

li {
  list-style: none;
}

ul {
  padding: 0;
}

.wrapper {
  padding: 1.4em;
}

.container {
  margin: auto;
  max-width: 540px;
  background: white;
  padding: 1.4em 1.5em 40px;
  border-radius: 12px;
}

.container h1 {
  /* margin: 0; */
  display: flex;
  align-items: center;
  gap: 16px;
}

.link-form {
  display: flex;
  align-items: center;
  justify-content: center;
  background: #dee2e6;
  padding-left: 20px;
  border-radius: 30px;
}

.link-form input {
  flex: 1;
  border: none;
  outline: none;
  background: transparent;
  padding: 10px;
}

.invalid input {
  color: red;
  border-bottom: 1px solid red;
}

.link-form button {
  padding: 16px;
  width: 132px;
  border: none;
  border-radius: 30px;
  background: rgb(110 168 254 / 0.4);
  color: #0d6efd;
  font-weight: 600;
  cursor: pointer;
  transition-property: background-color;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 150ms;
}

.link-form button:hover,
.link-form button:focus {
  background: rgb(110 168 254 / 0.5);
  font-weight: 700;
}

.link-form button:disabled {
  background: #ced4da;
  color: #343a40;
  cursor: not-allowed;
}

.link-form-error-msg {
  margin-bottom: 25px;
  margin-top: 4px;
  font-size: 0.8em;
  color: red;
  margin-left: 32px;
}

.bookmark-list-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.icon-button {
  border: none;
  background-color: transparent;
  appearance: none;
  outline: none;
  display: block;
  cursor: pointer;
  font-size: 16px;
  color: var(--secondary-icon-button);
  height: fit-content;
}

.icon-button:hover {
  color: var(--secondary-icon-button-active);
}

.bookmark-list {
  display: flex;
  justify-content: left;
  flex-direction: column;
  gap: 12px;
}

.bookmark-list-item {
  background: #e9ecef;
  border-radius: 6px;
}

.bookmark-list-wrapper {
  display: flex;
  flex-grow: 1;
  padding: 12px 8px;
  background: rgba(180, 180, 230, 0.2);
  border-radius: 6px;
  gap: 16px;
}

.bookmark-item-img {
  width: 64px;
  height: 64px;
  border-radius: 6px;
  background: #ced4da;
}

@media (max-width: 768px) {
  .bookmark-item-img {
    width: 48px;
    height: 48px;
  }
}

img {
  width: 100%;
  height: 100%;
  border-radius: 6px;
}

.bookmark-list-item-content {
  display: flex;
  flex-direction: column;
  width: 58%;
}

.bookmark-list-item-content h3 {
  margin: 0;
  font-size: 1.2em;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.bookmark-list-item-content a {
  text-decoration: none;
  color: #6c757d;
  font-size: 0.8em;
  width: 100%;
  margin-top: auto;

  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.bookmark-list-item-action {
  display: flex;
  flex-wrap: wrap;
  justify-content: end;
}

.bookmark-item-date {
  margin-top: auto;
  text-align: right;
  padding-right: 12px;
  font-size: 0.6em;
  color: #6c757d;
  width: max-content;
}

@media (max-width: 768px) {
  .bookmark-item-date {
    display: none;
  }
}

.bookmark-list-item-warper {
  display: flex;
  flex-direction: column;
  margin-left: auto;
}

.bookmark-list-edit-input {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  padding: 0 24px 8px 24px;
  font-size: 0.8em;
}

.bookmark-list-edit-input-wrapper {
  background: white;
  padding: 0px 8px 8px;
  border-radius: 0 0 8px 8px;
}

.bookmark-list-edit-input p {
  margin: 0;
  margin-bottom: 4px;
  font-weight: 700;
}

.bookmark-list-edit-input input {
  border: none;
  outline: none;
  background: transparent;
  border-bottom: 1px solid black;
}

.invalid {
  color: red;
  border-color: red !important;
}

.save-button {
  border: none;
  color: green;
  font-weight: 600;
  cursor: pointer;
  padding: 8px;
  background: white;
  border-radius: 8px;
}

.save-button:hover,
.save-button:focus {
  scale: 1.1;
}

.hidden {
  display: none;
}

.pagination {
  display: flex;
  justify-content: center;
}

.page-link {
  cursor: pointer;
  padding: 5px;
  margin: 2px;
}

.page-link.active {
  background-color: #eee;
  font-weight: bold;
  border-radius: 4px;
}

.submission-info-img {
  width: 100%;
  min-height: 300px;
  border-radius: 6px;
  background: #ced4da;
  cursor: pointer;
}

.return-button {
  padding: 16px;
  border: none;
  background: rgb(110 168 254 / 0.4);
  font-weight: 600;
  cursor: pointer;
  width: 100%;
  border-radius: 8px;
  color: #0d6efd;
  font-weight: 700;
  font-size: 20px;
}
